<template>
  <div class="player">
    <div class="player_main">
      <div class="vertical_title flex">{{ title }}</div>
      <CustomForm :formList="formList" :isSubmit="isSubmit" @submitForm="submitForm"></CustomForm>
      <div class="price flex_direction_column">
        <span>报名费用</span>
        <span class="flex">¥{{ price || form.price }}</span>
        <div class="xy flex">
          <div class="check" @click="check = !check">
            <img v-if="check" src="@/assets/image/icon/13.png" />
          </div>
          <p class="flex" @click="isClause = !isClause">《参赛条款》</p>
        </div>
        <div class="btn flex">
          <div class="box" @click="cancelClick">取消</div>
          <div class="box" @click="nextClick">下一步</div>
        </div>
      </div>
    </div>
    <ClausePopup
      title="参赛条款"
      :html="$store.state.config.treaty"
      :isClause="isClause"
      @close="closeClause"
    ></ClausePopup>
  </div>
</template>

<script>
import { addMatchOrder } from '@/service/api/match'
import { addActiveOrder } from '@/service/api/active'
import CustomForm from '@/components/form/custom.vue'
import ClausePopup from '@/components/popup/clause.vue'
export default {
  props: ['title', 'form'],
  components: { ClausePopup, CustomForm },
  data() {
    return {
      formList: [],
      check: false,
      isSubmit: false,
      isClause: false,
      price: sessionStorage.getItem('price')
    }
  },
  mounted() {},
  methods: {
    // 活动报名提交
    async addActiveOrder() {
      const res = await addActiveOrder({
        id: this.$route.query.id,
        formList: this.formList
      })
      if (res.code == 1) {
        let obj = {
          formList: this.formList,
          orderId: res.msg.id
        }
        this.$emit('next', obj)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 赛事报名提交
    async addMatchOrder() {
      const res = await addMatchOrder({
        id: this.$route.query.id,
        formList: this.formList
      })
      if (res.code == 1) {
        let obj = {
          formList: this.formList,
          orderId: res.msg.id
        }
        this.$emit('next', obj)
      } else {
        this.$message.error(res.msg)
      }
      console.log(res)
    },
    closeClause() {
      this.isClause = false
    },
    submitForm(form) {
      this.isSubmit = false
      if (JSON.stringify(form) == '{}') return
      for (let i = 0; i < this.formList.length; i++) {
        Object.keys(form).forEach((key) => {
          if (this.formList[i].model == key) {
            this.formList[i].value = form[key]
          }
        })
      }
      sessionStorage.setItem('form', JSON.stringify(form))
      sessionStorage.setItem('price', this.form.price ?? this.price)
      sessionStorage.setItem('formList', JSON.stringify(this.formList))

      // console.log(this.$route)
      if (this.$route.path == '/activity-entry') {
        this.addActiveOrder()
      } else {
        this.addMatchOrder()
      }

      // this.$emit('next', this.formList)
    },
    cancelClick() {
      this.$router.go(-1)
    },
    nextClick() {
      if (this.check) {
        this.isSubmit = true
      } else {
        this.$message.warning('请勾选协议')
      }
    }
  },
  watch: {
    form: {
      handler(val) {
        localStorage.setItem('entryType', 1)
        this.formList = val.sign_form ?? val
      },
      // deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.player {
  margin-top: 20px;
  border-radius: 3px;
  margin-bottom: 45px;
  background-color: #fff;
  .player_main {
    padding: 36px 45px 52px 45px;
    .form {
      padding: 20px 16px 0 16px;
      border-bottom: 1px solid #eee;
    }
    .price {
      margin-top: 32px;
      padding-left: 16px;
      span {
        &:nth-child(1) {
          height: 28px;
          line-height: 28px;
          font-size: @font_size_20;
        }
        &:nth-child(2) {
          margin-top: 12px;
          color: @color_one;
          font-size: @font_size_26;
          &::before {
            content: '\5171\8ba1\ff1a';
            // width: 45px;
            height: 25px;
            color: #000;
            line-height: 25px;
            font-size: @font_size_18;
          }
        }
      }
      .xy {
        margin-top: 36px;
        .check {
          width: 24px;
          height: 24px;
          cursor: pointer;
          border-radius: 3px;
          border: 1px solid #eee;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          cursor: pointer;
          color: @color_fifteen;
          font-size: @font_size_18;
          &::before {
            content: '\6211\5df2\9605\8bfb\5e76\540c\610f';
            margin-right: 5px;
            color: @color_six;
          }
        }
      }
    }
    .btn {
      margin-top: 36px;
      .box {
        width: 130px;
        height: 42px;
        cursor: pointer;
        line-height: 42px;
        text-align: center;
        border-radius: 3px;
        &:nth-child(1) {
          margin-right: 30px;
          color: @color_sixteen;
          background-color: #f9f9f9;
          border: 1px solid #cccccc;
        }
        &:nth-child(2) {
          color: #fff;
          background-color: @color_one;
        }
      }
    }
  }
}
</style>
