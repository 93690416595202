<template>
  <div>
    <el-dialog
      title="上传成绩"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      top="0"
      width="950px"
      @close="close"
    >
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/15.png" />
        <div class="dialog_title">
          <div class="title_main flex_direction_column">
            <span>{{ title }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="dialog_wrap">
          <div class="dialog_main">
            <div class="top flex_space_between">
              <div class="label">上传成绩</div>
              <div class="right flex_direction_column">
                <!-- 跑步总用时 -->
                <div class="box flex_space_between">
                  <div class="box_left flex">
                    <img src="@/assets/image/icon/25.png" />
                    <span>跑步总用时</span>
                  </div>
                  <div class="time_right flex">
                    <div class="input flex">
                      <input placeholder="0" v-model="params.hour" />
                      <span>小时</span>
                    </div>
                    <div class="input flex">
                      <input placeholder="0" v-model="params.minute" />
                      <span>分钟</span>
                    </div>
                    <div class="input flex">
                      <input placeholder="0" v-model="params.second" />
                      <span>秒</span>
                    </div>
                  </div>
                </div>
                <!-- 公里数 -->
                <div class="box kil flex_space_between">
                  <div class="box_left flex">
                    <img class="kil_img" src="@/assets/image/icon/24.png" />
                    <span>公里数（KM）</span>
                  </div>
                  <div class="box_right">
                    <input placeholder="请输入" v-model="params.mileage" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom flex_space_between">
              <div class="label">跑步轨迹</div>
              <div class="right flex_direction_column">
                <div class="right_top">
                  <div class="upload flex_direction_column">
                    <img v-if="params.images" class="preview" :src="params.images" alt="" />
                    <img v-if="!params.images" src="@/assets/image/img/38.png" />
                    <span v-if="!params.images">支持JPG/PNG/GIF格式，大小不超过5M</span>
                    <input type="file" class="upload-img-file" @change="ossUploadImg($event)" />
                  </div>
                </div>
                <div class="tips">注：上传您的成绩证明（上传第三方跑步软件的成绩截图）</div>
                <div class="btn" @click="confirmClick">确认上传</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadAch } from '@/service/api/user'
import { ossUpload, isObjEmpty } from '@/utils/tools'
export default {
  props: {
    userID: {
      type: Number,
      default() {
        return 0
      }
    },
    isScore: {
      type: Boolean,
      default() {
        return false
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data() {
    return {
      dialogVisible: false,
      fileList: [],
      params: {
        id: this.userID,
        hour: '',
        second: '',
        minute: '',
        images: '',
        mileage: ''
      }
    }
  },

  mounted() {},

  methods: {
    // oss上传
    ossUploadImg(e) {
      let timestamp = new Date().getTime()
      var file = e.currentTarget.files[0]
      let fileType = file.name.substring(file.name.lastIndexOf('.')) || '.jpg'
      ossUpload('pc/' + `${timestamp}${fileType}`, file).then((res) => {
        this.params.images = res
      })
    },
    // 上传成绩
    async uploadAch() {
      const res = await uploadAch(this.params)
      if (res.code == 1) {
        this.dialogVisible = false
        this.$emit('success', this.params)
        this.$message.success('上传成功')
      }
    },
    close() {
      this.dialogVisible = false
      this.$emit('close', false)
    },
    // 确认
    confirmClick() {
      if (isObjEmpty(this.params)) return this.$message('请填写完整信息')
      this.uploadAch()
    }
  },
  watch: {
    isScore: {
      handler(val) {
        this.dialogVisible = val
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog--center .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__title {
  height: 50px;
  line-height: 50px;
  color: @color_six;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_16;
  background-color: #eeeeee;
}
/deep/ .dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.upload-img-file {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  cursor: pointer;
  position: absolute;
}
.close {
  top: 18px;
  right: 22px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
}
.dialog_content {
  width: 100%;
  background-color: #fff;
  .dialog_title {
    width: 100%;
    .title_main {
      padding: 30px 132px 30px 182px;
      span {
        &:nth-child(1) {
          color: #000;
          height: 33px;
          font-size: 24px;
          font-weight: bold;
          line-height: 33px;
        }
        &:nth-child(2) {
          height: 25px;
          margin-top: 9px;
          line-height: 25px;
          color: @color_seven;
          font-size: @font_size_18;
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 12px;
    background: #fcfcfc;
  }
  .dialog_wrap {
    width: 100%;
    .dialog_main {
      padding: 33px 132px 43px 182px;
      .top {
        width: 100%;
        align-items: flex-start;
        .label {
          width: 87px;
          height: 25px;
          line-height: 25px;
          margin-right: 49px;
          font-size: @font_size_18;
        }
        .right {
          width: 500px;
          .kil {
            margin-top: 36px;
          }
          .box {
            padding-bottom: 16px;
            border-bottom: 1px solid #eee;

            .box_left {
              .kil_img {
                width: 16px !important;
              }
              img {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
              span {
                height: 25px;
                line-height: 25px;
                font-size: @font_size_18;
              }
            }
            .time_right {
              .input {
                margin-right: 49px;
                font-size: @font_size_18;
                &:nth-child(3) {
                  margin-right: 0;
                }
                input {
                  width: 30px;
                  height: 25px;
                  text-align: right;
                  // color: #bbbbbb;
                  margin-right: 10px;
                }
                span {
                  color: #000;
                  height: 25px;
                  line-height: 25px;
                }
              }
            }
            .box_right {
              width: 238px;
              font-size: @font_size_18;
              input {
                width: 100%;
                text-align: right;
              }
            }
          }
        }
      }
      .bottom {
        margin-top: 37px;
        align-items: flex-start;
        .label {
          height: 25px;
          line-height: 25px;
          font-size: @font_size_18;
        }
        .right {
          width: 500px;
          .right_top {
            width: 276px;
            height: 180px;
            border-radius: 3px;
            background-color: #fcfcfc;
            border: 1px solid #dddddd;
            .preview {
              width: 100% !important;
              height: 100% !important;
              object-fit: cover;
            }
            .upload {
              width: 276px;
              height: 180px;
              align-items: center;
              justify-content: center;
              position: relative;
              img {
                width: 50px;
                height: 50px;
              }
              span {
                height: 45px;
                width: 196px;
                margin-top: 27px;
                color: #bbbbbb;
                line-height: 25px;
                text-align: center;
                font-size: @font_size_14;
              }
            }
          }
          .tips {
            height: 20px;
            margin-top: 17px;
            color: #999999;
            line-height: 20px;
            font-size: @font_size_14;
          }
          .btn {
            color: #fff;
            width: 114px;
            height: 36px;
            cursor: pointer;
            margin-top: 40px;
            text-align: center;
            line-height: 36px;
            border-radius: 3px;
            font-size: @font_size_16;
            background-color: @color_one;
          }
        }
      }
    }
  }
}
</style>
