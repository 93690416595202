<template>
  <el-dialog title="" center :show-close="false" :visible.sync="dialogVisible" width="718px" @close="close">
    <div class="dialog_content">
      <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/12.png" />
      <div class="pay flex_direction_column">
        <img v-if="$store.state.payStatus == 0" src="../../assets/image/img/42.png" alt="" />
        <img v-else src="../../assets/image/img/43.png" alt="" />
        <span>{{ payStatus == 0 ? '支付成功' : '支付失败' }}</span>
        <p>{{ payStatus == 0 ? '您好，提交的订单已报名成功！' : '您提交的订单报名失败~' }}</p>
      </div>
      <div class="btn flex_center">
        <div class="box" @click="btnClick(1)">{{ payStatus == 0 ? '查看报名' : '返回首页' }}</div>
        <div class="box" @click="btnClick(2)">{{ payStatus == 0 ? '返回首页' : '去支付' }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      payStatus: 0
    }
  },

  mounted() {},

  methods: {
    btnClick(status) {
      if (status == 1) {
        if (this.payStatus == 0) {
          this.$router.push('/mine')
        } else {
          this.$router.push('/')
        }
      } else {
        if (this.payStatus == 0) {
          this.$router.push('/')
        }
      }
      this.dialogVisible = false
    },
    close() {
      this.dialogVisible = false
      this.$emit('close', false)
    }
  },
  watch: {
    '$store.state.payPopup': {
      handler(val) {
        if (val) {
          this.dialogVisible = val
          this.payStatus = this.$store.state.payStatus
        }
        console.log(val)
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 0;
}

/deep/ .dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.close {
  top: 21px;
  right: 17px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: absolute;
}

.dialog_content {
  width: 100%;
  border-radius: 5px;
  padding-bottom: 50px;

  .pay {
    margin-top: 60px;
    align-items: center;
    justify-content: center;
    img {
      width: 43px;
      height: 43px;
    }
    span {
      height: 25px;
      margin-top: 14px;
      font-size: 18px;
      line-height: 25px;
    }
    p {
      height: 20px;
      font-size: 14px;
      color: #666666;
      margin-top: 18px;
    }
  }
  .btn {
    margin-top: 50px;
    .box {
      cursor: pointer;
      width: 130px;
      height: 42px;
      font-size: 16px;
      line-height: 42px;
      text-align: center;
      border-radius: 5px;
      &:nth-child(1) {
        margin-right: 42px;
        color: #e6425e;
        background-color: #f9f9f9;
      }
      &:nth-child(2) {
        color: #fff;
        background-color: #e6425e;
      }
    }
  }
}
</style>
