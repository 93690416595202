<template>
  <div>
    <el-dialog
      :title="title"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      top="0"
      width="1175px"
      @close="close"
    >
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/15.png" />
        <div class="rich" v-html="html"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isClause: {
      type: Boolean,
      default() {
        return false
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
    html: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  mounted() {},

  methods: {
    close() {
      this.$emit('close', false)
    }
  },
  watch: {
    isClause(val) {
      this.dialogVisible = val
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__title {
  height: 50px;
  line-height: 50px;
  color: @color_six;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_16;
  background-color: #eeeeee;
}
.close {
  top: 18px;
  right: 22px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
}
.rich {
  overflow-y: auto;
  max-height: 460px;
  line-height: 30px;
  padding: 0 36px 45px 36px;
  font-size: @font_size_18;
  /* 滚动条宽度 */
  &::-webkit-scrollbar {
    width: 8px;
  }
  /* 滚动条背景颜色 */
  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }
  /* 滚动条当前背景颜色 */
  &::-webkit-scrollbar-thumb {
    background-color: #dddddd;
  }
}
</style>
