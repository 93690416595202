<template>
  <div class="entry" v-if="info">
    <div class="entry_main">
      <div class="vertical_title flex">
        {{ title }}
        <!-- checked ==1 未核销 2 已核销 3 已过期 -->
        <!-- 单人/团队 -->
        <!-- isSignIn = !isSignIn -->
        <div class="qrcode flex" v-if="info.order.state == 2 && info.active.type != 2" @click="qrcode(info.order)">
          <img :src="getChecked(info.order).url" />
          <span class="flex" :style="getChecked(info.order).style">{{ getChecked(info.order).text }}</span>
        </div>
        <!-- 线上 / 待参与 -->
        <div
          class="online"
          v-if="info.order.state == 2 && info.active.type == 2 && info.user.is_upload != 1"
          @click="isScore = !isScore"
        >
          成绩打卡
        </div>
        <!-- 线上 / 已完成 -->
        <div
          class="online"
          v-if="info.order.type == 2 && info.user.is_upload == 1"
          style="background: #f9f9f9; color: #e6425e"
          @click="isReult = !isReult"
        >
          成绩打卡
        </div>
      </div>
      <div class="form flex_direction_column">
        <!-- 参赛号 -->
        <!-- <div class="num flex" v-if="info.order.state == 2 && info.active.type == 2">
          <span>参赛号</span>
          <span>{{ info.user.entry_no }}</span>
        </div> -->
        <div class="item flex" v-for="(i, index) in formList" :key="index">
          <div class="label">{{ i.model }}</div>
          <div class="desc">
            <span v-if="i.type != 6 && i.type != 8 && i.type != 9">{{ i.value }}</span>
            <div class="flex_wrap" v-if="i.type == 6">
              <span v-for="(ii, vv) in i.value" :key="vv">{{ ii }}</span>
            </div>
            <div class="flex_wrap" v-if="i.type == 8 || i.type == 9">
              <img v-for="(ii, vv) in i.value" :key="vv" :src="ii" />
            </div>
          </div>
        </div>
      </div>
      <div class="price flex_direction_column">
        <span>报名费用</span>
        <span v-if="price != '0.0'">¥{{ price }}</span>
        <span v-else>免费</span>
      </div>
    </div>
    <!-- 签到码弹窗 -->
    <SigInPopup :isSignIn="isSignIn" :info="info.codeData" @close="close"></SigInPopup>
    <!-- 上传成绩弹窗 -->
    <ScorePopup
      :isScore="isScore"
      :userID="info.user.id"
      :title="info.active.name"
      @success="scoreSuccess"
      @close="close"
    ></ScorePopup>
    <!-- 我的成绩弹窗 -->
    <ResultePopup :userId="info.user.id" :isReult="isReult" @close="close"></ResultePopup>
  </div>
</template>

<script>
import { matchCashier } from '@/service/api/index'

import ScorePopup from '@/components/popup/score.vue'
import SigInPopup from '@/components/popup/signin.vue'
import ResultePopup from '@/components/popup/result.vue'
export default {
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    id: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: { SigInPopup, ScorePopup, ResultePopup },
  data() {
    return {
      info: '',
      isSignIn: false,
      isScore: false,
      isReult: false,
      price: sessionStorage.getItem('price') ?? 0,
      formList: JSON.parse(sessionStorage.getItem('formList')) ?? []
    }
  },

  mounted() {},

  methods: {
    // 收银台
    async matchCashier(id) {
      const res = await matchCashier({ id })
      if (res.code == 1) {
        this.info = res.msg
        this.formList = res.msg.user.sign_form
        this.price = res.msg.order.total_price
        res.msg.order.time = res.msg.time
        this.$emit('change', res.msg.order)
      }
    },
    getChecked(item) {
      if (item.checked == 1) {
        return {
          text: '未核销',
          style: 'color:#E6425E',
          url: require('@/assets/image/img/36.png')
        }
      } else if (item.checked == 2) {
        return {
          text: '已核销',
          style: 'color:#6FBA2C',
          url: require('@/assets/image/img/37.png')
        }
      } else if (item.checked == 3) {
        return {
          text: '已过期',
          style: 'color:#777777',
          url: require('@/assets/image/img/37.png')
        }
      }
    },
    scoreSuccess() {},
    close() {
      this.isSignIn = false
      this.isScore = false
      this.isReult = false
    },
    // 打开签到码弹窗
    qrcode(item) {
      if (this.getChecked(item).text != '已过期') return (this.isSignIn = true)
      this.$message('已过期')
    }
  },
  watch: {
    id: {
      handler(val) {
        // 收银台
        this.matchCashier(val)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.vertical_title {
  position: relative;
  .qrcode {
    right: 0;
    cursor: pointer;
    position: absolute;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    span {
      height: 30px;
      line-height: 30px;
      font-weight: normal;
      color: @color_one;
      font-size: @font_size_18;
      &::after {
        content: '\ff08\70b9\51fb\67e5\770b\5927\56fe\ff09';
        height: 20px;
        font-size: 14px;
        margin-left: 5px;
        line-height: 20px;
        color: @color_eight;
      }
    }
  }
  .online {
    right: 0;
    width: 100px;
    height: 32px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    line-height: 32px;
    text-align: center;
    border-radius: 3px;
    background: @color_one;
    font-size: @font_size_14;
  }
}
.entry {
  margin-top: 20px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: #fff;
  .entry_main {
    padding: 31px 47px 43px 47px;
    .form {
      position: relative;
      padding: 20px 16px;
      border-bottom: 1px solid #eee;
      .num {
        right: 0;
        position: absolute;
        span {
          &:nth-child(1) {
            height: 22px;
            line-height: 22px;
            margin-right: 28px;
            font-size: @font_size_16;
          }
          &:nth-child(2) {
            height: 61px;
            padding: 0 16px;
            font-size: 50px;
            font-weight: bold;
            line-height: 61px;
            color: @color_one;
            background-color: @color_bg_two;
          }
        }
      }
      .item {
        margin-bottom: 10px;
        .label {
          width: 137px;
          height: 40px;
          line-height: 40px;
          color: @color_seven;
        }
        .desc {
          margin-left: 39px;
          line-height: 40px;
          font-size: @font_size_16;
          span {
            margin-right: 10px;
          }
          img {
            width: 148px;
            height: 148px;
            cursor: pointer;
            object-fit: cover;
            margin-right: 10px;
            border-radius: 6px;
          }
        }
      }
    }
    .price {
      margin-top: 28px;
      line-height: 28px;
      span {
        &:nth-child(1) {
          font-size: @font_size_20;
        }
        &:nth-child(2) {
          // font-weight: bold;
          line-height: 25px;
          margin-top: 12px;
          color: @color_one;
          font-size: @font_size_26;
          &::before {
            content: '\5171\8ba1\ff1a';
            color: #000;
            font-weight: normal;
            font-size: @font_size_18;
          }
        }
      }
    }
  }
}
</style>
