<template>
  <div class="countdown flex">
    您的订单将与
    <p>{{ text }}</p>
    秒后取消，请尽快支付吧~
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      text: '',
      timer: null
    }
  },

  mounted() {
    this.countDown()
  },

  methods: {
    countDown() {
      var times = this.time
      this.timer = setInterval(() => {
        var day = 0,
          hour = 0,
          minute = 0,
          second = 0 //时间默认值
        if (times > 0) {
          day = Math.floor(times / (60 * 60 * 24))
          hour = Math.floor(times / (60 * 60)) - day * 24
          minute = Math.floor(times / 60) - day * 24 * 60 - hour * 60
          second = Math.floor(times) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
        }
        if (day <= 9) day = '0' + day
        if (hour <= 9) hour = '0' + hour
        if (minute <= 9) minute = '0' + minute
        if (second <= 9) second = '0' + second
        times--
        this.text = minute + '：' + second

        if (times <= 0) {
          this.$emit('end', true)
          clearInterval(this.timer)
        }
      }, 1000)
    }
  },
  destroyed() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-statistic {
  width: auto !important;
}

.countdown {
  // width: 100%;
  padding: 39px;
  background-color: #fff;
  font-size: 24px;
  margin: 20px 0;
  p {
    font-size: 30px;
    color: #e6425e;
    display: block;
  }
}
</style>
