<template>
  <div class="basic" v-if="info">
    <div class="basic_content flex_space_between">
      <!-- 已报名 -->
      <img class="sign" v-if="info.state == 1" src="@/assets/image/img/33.png" />
      <!-- 已完成 -->
      <img class="sign" v-if="info.state == 2" src="@/assets/image/img/32.png" />
      <!-- 已退款 -->
      <img class="sign" v-if="info.state == 3" src="@/assets/image/img/41.png" />
      <div class="basic_left flex_direction_column">
        <div class="top flex">
          <div class="pic">
            <img :src="info.active.cover" />
          </div>
          <div class="info flex_direction_column">
            <span>{{ info.active.name }}</span>
            <div class="desc">
              <p>参赛地点：{{ info.active.province }}·{{ info.active.city }}</p>
              <p>比赛时间：{{ info.active.sign_start }}</p>
              <span @click="detail">详情 ></span>
            </div>
          </div>
        </div>
        <div class="bottom flex_wrap">
          <div class="item flex">
            <img src="@/assets/image/icon/03.png" />
            <span>{{ info.club_name }}</span>
          </div>
        </div>
      </div>
      <div class="basic_right">
        <div class="mess flex_direct_column" :style="status == 2 ? 'bottom:58px' : ''">
          <p v-if="status != 2">报名组别：{{ info.group.name }}</p>
          <p>报名费用：{{ info.group.price }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['status', 'info'],
  data() {
    return {}
  },

  mounted() {},

  methods: {
    detail() {
      var oPath = this.$route.path
      var nPath = ''
      if (oPath == '/activity-entry') {
        nPath = 'activity-detail'
      } else {
        nPath = 'match-detail'
      }
      this.$router.push({
        path: `/${nPath}`,
        query: { id: this.info.active.id }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.basic {
  background-color: #fff;
  .basic_content {
    position: relative;
    padding: 33px 40px 15px 40px;
    .sign {
      top: 0;
      right: 57px;
      width: 80px;
      height: 47px;
      position: absolute;
    }
    .basic_left {
      .top {
        padding-right: 57px;
        align-items: flex-start;
        border-right: 1px solid #eee;
        .pic {
          width: 268px;
          height: 180px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
        .info {
          margin-left: 20px;
          span {
            width: 417px;
            // font-weight: bold;
            line-height: 42px;
            margin-bottom: 18px;
            font-size: @font_size_26;
          }
          .desc {
            width: 482px;
            height: 70px;
            line-height: 40px;
            position: relative;
            color: @color_seven;
            font-size: @font_size_16;
            span {
              right: 0;
              bottom: 0;
              width: 65px;
              height: 25px;
              cursor: pointer;
              margin-bottom: 0;
              line-height: 25px;
              color: @color_one;
              position: absolute;
              font-weight: normal;
              font-size: @font_size_18;
            }
          }
        }
      }
      .bottom {
        margin-top: 12px;
        .item {
          margin-right: 10px;
          img {
            width: 22px;
            height: 22px;
          }
          span {
            height: 22px;
            margin-left: 5px;
            line-height: 22px;
            color: @color_seven;
            font-size: @font_size_16;
          }
        }
      }
    }
    .basic_right {
      flex: 1;
      height: 180px;
      margin-left: 48px;
      position: relative;

      .mess {
        bottom: 17px;
        line-height: 40px;
        position: absolute;
        color: @color_seven;
        font-size: @font_size_16;
      }
    }
  }
}
</style>
