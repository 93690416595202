<template>
  <div class="order">
    <div class="order_main">
      <div class="vertical_title flex">订单信息</div>
      <div class="desc flex_direction_column">
        <div class="item flex">
          <span>订单编号</span>
          <span>{{ order.out_trade_no }}</span>
        </div>
        <div class="item flex">
          <span>下单时间</span>
          <span>{{ order.create_time }}</span>
        </div>
        <div class="item flex" v-if="order.pay_time">
          <span>支付方式</span>
          <span v-if="order.pay_way == 1">微信支付</span>
          <span v-if="order.pay_way == 2">支付宝支付</span>
        </div>
        <div class="item flex" v-if="order.pay_time">
          <span>支付时间</span>
          <span>{{ order.pay_time }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.order {
  margin-bottom: 21px;
  border-radius: 3px;
  background-color: #fff;
  .order_main {
    padding: 27px 47px 29px 47px;
    .desc {
      margin-top: 24px;
      .item {
        span {
          height: 36px;
          line-height: 36px;
          font-size: @font_size_16;
          &:nth-child(1) {
            width: 137px;
            color: @color_six;
            margin-right: 39px;
          }
        }
      }
    }
  }
}
</style>
