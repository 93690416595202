<template>
  <div class="content" v-if="Object.keys(ruleForm).length !== 0 && formList">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="demo-ruleForm">
      <el-form-item :label="i.label || i.model" :prop="i.prop ? i.model : ''" v-for="i in formList" :key="i.model">
        <!-- 单行文本 -->
        <el-input v-if="i.type == 1" v-model="ruleForm[i.model]" :placeholder="placeholder(i)"></el-input>
        <!-- 多行文本 -->
        <el-input
          v-if="i.type == 2"
          type="textarea"
          v-model="ruleForm[i.model]"
          :placeholder="placeholder(i)"
        ></el-input>
        <!-- 下拉框 -->
        <el-select v-if="i.type == 3" v-model="ruleForm[i.model]" :placeholder="placeholder(i)">
          <el-option v-for="(ii, vv) in i.child" :key="vv" :label="ii.label" :value="ii.value || ii.label"></el-option>
        </el-select>
        <!-- 日期选择器 -->
        <el-date-picker
          v-if="i.type == 4"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          :placeholder="placeholder(i)"
          v-model="ruleForm[i.model]"
          style="width: 50%"
        ></el-date-picker>
        <!-- 时间选择器 -->
        <el-time-picker
          v-if="i.type == 5"
          format="HH 时 mm 分 ss 秒"
          value-format="HH-mm-ss"
          :placeholder="placeholder(i)"
          v-model="ruleForm[i.model]"
          style="width: 50%"
        ></el-time-picker>
        <!-- 多选 -->
        <el-checkbox-group v-if="i.type == 6" v-model="ruleForm[i.model]">
          <el-checkbox v-for="(ii, vv) in i.child" :key="vv" :label="ii.label" :name="ii.label"></el-checkbox>
        </el-checkbox-group>
        <!-- 单选 -->
        <el-radio-group v-if="i.type == 7" v-model="ruleForm[i.model]">
          <el-radio v-for="(ii, vv) in i.child" :key="vv" :label="ii.label"></el-radio>
        </el-radio-group>
        <!-- 上传图片到服务器 -->
        <div v-if="i.type == 8" @click="handleClick(i)">
          <el-upload
            ref="uploadName"
            :on-success="handleSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :limit="i.limit ? i.limit : 1"
            :action="i.action"
            list-type="picture-card"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="upload.dialog">
            <img width="100%" :src="upload.url" alt="" />
          </el-dialog>
          <div slot="tip" class="el-upload__tip">最多上传{{ i.limit ? i.limit : 1 }}张图片</div>
        </div>
        <!-- 上传到OSS -->
        <div class="flex_wrap" v-if="i.type == 9" @click="handleClick(i)">
          <div
            class="oss-upload flex border"
            v-if="ruleForm[i.model].length != 0 || (ruleForm[upload.model] && ruleForm[upload.model].length != 0)"
          >
            <img v-for="(item, index) in ruleForm[i.model] || ruleForm[upload.model]" :key="index" :src="item" alt="" />
          </div>
          <div class="oss-upload">
            <i class="el-icon-plus"></i>
            <input type="file" class="upload-img-file" @change="ossUploadImg($event)" />
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ossUpload } from '@/utils/tools'
export default {
  props: {
    isTeam: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 表单列表
    formList: {
      type: Array,
      default() {
        return []
      }
    },
    // 触发提交
    isSubmit: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 触发清空
    isReset: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      /**
       * 自定义表单
       * @param formList
       *
       * 公共参数
       * 必填：
       * type（Number）表单类型
       * model（String）需要的参数名
       * 可选
       * prop（Boolean） 是否开启校验（默认false）
       * placeholder（String）提示文字（可不填，默认提示文字）
       *
       * type == 1 （单行文本）
       * 可选参数：
       * min（Number）最小字符 max（Number） 最大字符（min max 可不传 或 只传一个）
       * min = 3 max = 5
       * validate（Function）自定义校验规则
       *
       * type == 2 （多行文本）
       * 可选参数：
       * min（Number）最小字符 max（Number） 最大字符（min max 可不传 或 只传一个）
       * min = 3 max = 5
       * validate（Function）自定义校验规则
       *
       * type == 3 （下拉框）
       * 必填参数：
       * child（Array）=> 下拉列表（数组可为空）
       * child == [{label:'区域一'}]
       *
       * type == 4 日期选择器
       * type == 5 时间选择器
       *
       * type == 6 多选
       * 必填参数：
       * child（Array）=> 多选列表（数组可为空）
       * child = [{label:'线下主题活动'}]
       *
       * type == 7 单选
       * child（Array）=> 单选列表（数组可为空）
       * child = [{label:'线上品牌商赞助'}]
       *
       * type == 8 （上传图片）
       * 可选参数：
       * limit（Number） 限制上传个数（可不传默认1）
       * limit = 1
       * 必填参数
       * action（String）上传地址
       *
       * type == 9 （上传OSS）
       *
       * 自定义校验规则（列子）
       * const validatePhone = (rule, value, callback) => {
       * phoneReg(value)
       * .then(() => {
       *    callback()
       *  }).catch((err) => {
       *    return callback(new Error(err))
       *  })
       * }
       *
       */
      rules: {},
      ruleForm: {},
      upload: {
        url: '',
        model: '',
        dialog: false
      }
    }
  },
  mounted() {},
  methods: {
    // oss上传
    ossUploadImg(e) {
      let timestamp = new Date().getTime()
      var file = e.currentTarget.files[0]
      let fileType = file.name.substring(file.name.lastIndexOf('.')) || '.jpg'
      ossUpload('pc/' + `${timestamp}${fileType}`, file).then((res) => {
        this.ruleForm[this.upload.model].push(res)
      })
    },
    // 解析字段
    resolve(arr) {
      const params = {
        姓名: 'name',
        手机号: 'mobile',
        性别: 'sex',
        证件类型: 'card_type',
        证件号: 'id_card',
        证件号码: 'id_card',
        出生日期: 'birthday',
        衣服尺码: 'size',
        紧急联系人: 'contacts',
        紧急联系方式: 'contact_no',
        血型: 'blood_type'
      }
      arr.forEach((i) => {
        // 多选 上传图片
        if (i.type != 6 && i.type != 8 && i.type != 9) {
          // 1 单人 2 多人 3 个人中心
          if (localStorage.getItem('entryType') == 1) {
            for (const key in params) {
              if (i.model == key) {
                this.$set(this.ruleForm, i.model, this.$store.state.userInfo[params[key]])
              }
            }
          } else {
            this.$set(this.ruleForm, i.model, '')
          }
        } else {
          this.$set(this.ruleForm, i.model, [])
        }

        // 添加校验
        if (i.prop) {
          let Reg = null
          // 单行文本 多行文本
          if (i.type == 1 || i.type == 2) {
            Reg = [{ required: true, message: this.placeholder(i), trigger: 'blur' }]
            if (i.validate) {
              Reg.push({ validator: i.validate, trigger: 'blur' })
            } else if (i.min && i.max) {
              Reg.push({ min: i.min, max: i.max, message: `长度在 ${i.min} 到 ${i.max} 个字符`, trigger: 'blur' })
            } else if (i.min) {
              Reg.push({ min: i.min, message: `长度最少在 ${i.min} 个字符`, trigger: 'blur' })
            } else if (i.max) {
              Reg.push({ max: i.max, message: `长度最多在 ${i.max} 个字符`, trigger: 'blur' })
            }
          }
          // 下拉框
          else if (i.type == 3) {
            Reg = [{ required: true, message: this.placeholder(i), trigger: 'change' }]
          }
          // 日期选择器 时间选择器
          else if (i.type == 4 || i.type == 5) {
            Reg = [{ required: true, message: this.placeholder(i), trigger: 'change' }]
          }
          // 多选 上传图片
          else if (i.type == 6 || i.type == 8 || i.type == 9) {
            Reg = [{ required: true, message: this.placeholder(i), trigger: 'change' }]
          }
          // 单选
          else if (i.type == 7) {
            Reg = [{ required: true, message: this.placeholder(i), trigger: 'change' }]
          }

          this.$set(this.rules, i.model, Reg)
        }
      })

      sessionStorage.setItem('rules', JSON.stringify(this.rules))
    },
    // 提示文字
    placeholder(i) {
      // 单行文本 多行文本
      if (i.type == 1 || i.type == 2) {
        return i.placeholder ? i.placeholder : '请填写' + i.label
      }
      // 下拉框 日期选择器 时间选择器
      else if (i.type > 2 && i.type < 8) {
        return i.placeholder ? i.placeholder : '请选择' + i.label
      }
      // 上传图片
      else if (i.type == 8) {
        return i.placeholder ? i.placeholder : '请上传' + i.label
      }
    },
    // 点击upload
    handleClick(item) {
      this.upload.model = ''
      this.upload.model = item.model
    },
    // 上传成功的回调
    handleSuccess(response, file, fileList) {
      this.ruleForm[this.upload.model] = fileList
    },
    // 移除的回调
    handleRemove(file, fileList) {
      this.ruleForm[this.upload.model] = fileList
    },
    // 点击图片的回调
    handlePictureCardPreview(file) {
      this.upload.dialog = true
      this.upload.url = file.url
    },
    // 提交
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('submitForm', this.ruleForm)
        } else {
          return this.$emit('submitForm', {})
        }
      })
    },
    // 清空
    resetForm() {
      const uploadNameRefs = this.$refs.uploadName
      if (uploadNameRefs && Object.keys(uploadNameRefs).length !== 0) {
        uploadNameRefs.forEach((i, v) => {
          uploadNameRefs[v].clearFiles()
        })
      }
      this.$refs.ruleForm.resetFields()
      this.$emit('resetForm', false)
    }
  },
  watch: {
    '$store.state.teamForm': {
      handler(val) {
        let teamForm = val
        if (JSON.stringify(teamForm) != '{}') {
          this.ruleForm = teamForm
        } else {
          if (JSON.stringify(this.ruleForm) != '{}') {
            this.resetForm()
          }
          this.resolve(this.formList)
        }
      },
      immediate: true
    },
    formList: {
      handler(val) {
        let rules = JSON.parse(sessionStorage.getItem('rules'))
        let form = JSON.parse(sessionStorage.getItem('form'))
        this.rules = rules
        if (Object.getPrototypeOf(form) === Object.prototype) {
          this.ruleForm = form
        } else {
          if (!this.isTeam) {
            this.resolve(val)
          }
        }
      },
      deep: true
      // immediate: true
    },
    isSubmit(val) {
      if (val) {
        this.submitForm()
      }
    },
    isReset: {
      handler(val) {
        if (val) {
          this.resetForm()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
  font-size: 16px;
}
.content {
  width: 100%;
  background-color: #fff;
}
.border {
  width: auto !important;
  border: none !important;
  img {
    width: 148px !important;
    height: 148px !important;
  }
}
.oss-upload {
  width: 148px;
  height: 148px;
  cursor: pointer;
  text-align: center;
  line-height: 146px;
  border-radius: 6px;
  font-size: 28px;
  color: #8c939d;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  position: relative;
  margin-right: 10px;
  &:hover {
    border-color: #409eff;
  }
  img {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
  .upload-img-file {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    cursor: pointer;
    position: absolute;
  }
}
.demo-ruleForm {
  width: 50%;
  padding: 20px 0;
}
.el-radio-group {
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.el-radio {
  display: flex;
}
</style>
