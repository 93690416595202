<template>
  <div>
    <el-dialog
      title="签到码"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      top="0"
      width="664px"
      @close="close"
    >
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/15.png" />
        <div class="dialog_main flex_direction_column">
          <div class="user flex_direction_column">
            <img class="icon" src="../../assets/image/img/46.png" alt="" />
            <div class="u-item flex" v-for="item in info.user" :key="item.mobile">
              <div class="name">{{ item.name }}</div>
              <div class="phone">{{ item.mobile }}</div>
            </div>
          </div>
          <div class="qrcode flex_center" :style="info.checked == 1 ? '' : ' border: 2px solid #6FBA2C;'">
            <img :src="info.qrcode" />
          </div>
          <div class="num">{{ info.code }}</div>
          <div class="desc flex">
            <img v-if="info.checked == 1" src="@/assets/image/icon/21.png" />
            <img v-else src="@/assets/image/icon/22.png" />
            <span :style="info.checked == 1 ? '' : 'color:#6FBA2C'">{{ info.checked == 1 ? '未核销' : '已核销' }}</span>
          </div>
          <div class="save" @click="down">保存签到码</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { downloadIamge } from '@/utils/tools'
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    },
    isSignIn: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  mounted() {},

  methods: {
    close() {
      this.$emit('close', false)
    },
    down() {
      downloadIamge(this.info.qrcode, '签到码')
    }
  },
  watch: {
    isSignIn(val) {
      this.dialogVisible = val
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog--center .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__title {
  height: 50px;
  line-height: 50px;
  color: @color_six;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_16;
  background-color: #eeeeee;
}
/deep/ .dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  /* 滚动条宽度 */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* 滚动条背景颜色 */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  /* 滚动条当前背景颜色 */
  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e1;
  }
}
.close {
  top: 18px;
  right: 22px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
}
.dialog_content {
  width: 100%;
  background-color: #fff;
  .dialog_main {
    height: 700px;

    align-items: center;
    justify-content: center;
    .user {
      width: 100%;
      position: relative;
      margin-top: 25px;
      color: #000;
      border-bottom: 1px dashed #cccccc;
      .icon {
        width: 94px;
        height: 40px;
        left: 0;
        top: 43px;
        position: absolute;
      }
      .u-item {
        width: 436px;
        height: 48px;
        font-size: 30px;
        line-height: 48px;
        margin: 25px auto 0 auto;
        &:last-child {
          height: 75px !important;
        }
        .name {
          margin-right: 20px;
        }
      }
    }
    .qrcode {
      width: 436px;
      height: 436px;
      margin-top: 49px;
      border: 2px solid #dfbd96;
      img {
        width: 95%;
        height: 95%;
        object-fit: cover;
      }
    }
    .num {
      color: #000;
      height: 48px;
      margin-top: 14px;
      line-height: 48px;
      letter-spacing: 2px;
      font-size: @font_size_34;
    }
    .desc {
      margin-top: 10px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      span {
        height: 30px;
        line-height: 30px;
        color: @color_one;
        font-size: @font_size_22;
      }
    }
    .save {
      width: 664px;
      height: 64px;
      cursor: pointer;
      margin-top: 40px;
      line-height: 64px;
      text-align: center;
      color: @color_fifteen;
      font-size: @font_size_18;
      background-color: @color_bg_one;
    }
  }
}
</style>
