<template>
  <div class="payment">
    <div class="payment_main">
      <div class="vertical_title flex">选择支付方式</div>
      <div class="way flex_direction_column">
        <div class="item flex" v-for="(item, index) in wayList" :key="item.id" @click="wayClick(index)">
          <div class="check">
            <img v-if="index == wayIndex" src="@/assets/image/icon/14.png" />
          </div>
          <div class="desc flex">
            <img :src="item.imgSrc" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="btn flex_direction_column">
        <div class="box s1" @click="backClick" v-if="!orderId">返回修改</div>
        <div class="box s2" @click="confirmClick">确认付款</div>
      </div>
    </div>

    <el-dialog title="微信扫码支付" center :visible.sync="dialogVisible" width="50%" @close="close">
      <img class="code_url" :src="QRImgUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { getQRcode } from '@/utils/tools'
import { orderPay, orderPayCheck } from '@/service/api/index'
export default {
  props: ['orderId'],
  data() {
    return {
      QRImgUrl: '',
      timer: null,
      dialogVisible: false,
      wayIndex: 0,
      wayList: [
        { id: 1, name: '微信付款', imgSrc: require('@/assets/image/img/14.png') }
        // { id: 2, name: '支付宝付款', imgSrc: require('@/assets/image/img/13.png') }
      ]
    }
  },

  mounted() {},

  methods: {
    // 拉起支付
    async orderPay() {
      const res = await orderPay({
        id: this.orderId,
        pay_type: this.wayIndex + 1
      })
      this.dialogVisible = true
      getQRcode(res.msg.code_url)
        .then((url) => {
          this.QRImgUrl = url
          this.timer = setInterval(() => {
            this.orderPayCheck()
          }, 3000)
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 检查支付状态
    async orderPayCheck() {
      const res = await orderPayCheck({ id: this.orderId })
      if (res.code == 1) {
        clearInterval(this.timer)
        this.$store.state.payStatus = 0
        this.$store.state.payPopup = true
        this.$emit('success', res.msg.order)
        this.dialogVisible = false
      }
    },

    close() {
      this.dialogVisible = false
      clearInterval(this.timer)
    },
    wayClick(index) {
      this.wayIndex = index
    },
    backClick() {
      this.$emit('back', true)
    },
    confirmClick() {
      if (this.wayIndex == 1) return this.$message('暂未开通支付宝支付')
      this.orderPay()
    }
  }
}
</script>

<style lang="less" scoped>
.code_url {
  width: 300px;
  height: 300px;
  margin: 20px auto;
}
.payment {
  margin-bottom: 29px;
  background-color: #fff;
  .payment_main {
    padding: 31px 47px 43px 47px;
  }
  .way {
    .item {
      padding: 34px 0;
      border-bottom: 1px solid #eeeeee;
      .check {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-right: 37px;
        border-radius: 50%;
        border: 1px solid #eee;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .desc {
        img {
          width: 60px;
          height: 60px;
          margin-right: 17px;
        }
        span {
          height: 30px;
          line-height: 30px;
          font-size: @font_size_22;
        }
      }
    }
  }
  .btn {
    align-items: center;
    justify-content: center;
    .s1 {
      margin-top: 57px;
      color: @color_one;
      border: 1px solid @color_one;
    }
    .s2 {
      color: #fff;
      margin-top: 36px;
      background-color: @color_one;
    }
    .box {
      width: 600px;
      height: 52px;
      cursor: pointer;
      line-height: 52px;
      border-radius: 3px;
      text-align: center;
      font-size: @font_size_18;
    }
  }
}
</style>
