<template>
  <div v-if="info">
    <el-dialog
      title="我的成绩"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      top="0"
      width="950px"
      @close="close"
    >
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/15.png" />
        <div class="dialog_title">
          <div class="title_main flex_direction_column">
            <span>2022乐山半程马拉松线上赛</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="dialog_main flex_direction_column">
          <div class="item flex">
            <div class="label">上传成绩</div>
            <div class="desc flex_direction_column">
              <span
                >跑步总用时：{{ info.achievement.hour }}小时{{ info.achievement.minute }}分钟{{
                  info.achievement.hour
                }}秒</span
              >
              <span>公里数：{{ info.mileage }}km</span>
            </div>
          </div>
          <div class="item flex">
            <div class="label">跑步轨迹</div>
            <div class="desc">
              <img :src="info.images" />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { achievement } from '@/service/api/user'
export default {
  props: {
    isReult: {
      type: Boolean,
      default() {
        return false
      }
    },
    userId: {
      type: Number,
      default() {
        return 0
      }
    }
  },

  data() {
    return {
      info: '',
      dialogVisible: false
    }
  },

  mounted() {},

  methods: {
    async achievement() {
      const res = await achievement({ id: this.userId })
      this.info = res.msg
      console.log(res)
    },
    close() {
      this.dialogVisible = false
      this.$emit('close', false)
    }
  },
  watch: {
    isReult: {
      handler(val) {
        if (val) {
          this.achievement()
        }
        this.dialogVisible = val
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog--center .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__title {
  height: 50px;
  line-height: 50px;
  color: @color_six;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_16;
  background-color: #eeeeee;
}
/deep/ .dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.close {
  top: 18px;
  right: 22px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
}
.dialog_content {
  width: 100%;
  background-color: #fff;
  .dialog_title {
    width: 100%;
    .title_main {
      padding: 30px 132px 30px 182px;
      span {
        &:nth-child(1) {
          color: #000;
          height: 33px;
          font-size: 24px;
          font-weight: bold;
          line-height: 33px;
        }
        &:nth-child(2) {
          height: 25px;
          margin-top: 9px;
          line-height: 25px;
          color: @color_seven;
          font-size: @font_size_18;
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 12px;
    background: #fcfcfc;
  }
  .dialog_main {
    width: 636px;
    align-items: center;
    justify-content: center;
    margin-left: 45px;
    padding: 33px 0px 43px 0px;
    .item {
      width: 100%;
      align-items: flex-start;
      font-size: @font_size_18;
      .label {
        width: 87px;
        height: 25px;
        line-height: 25px;
        margin-right: 27px;
      }
      .desc {
        img {
          width: 276px;
          height: 180px;
          object-fit: cover;
          border-radius: 3px;
        }
        span {
          height: 25px;
          line-height: 25px;
          &:nth-child(2) {
            margin-top: 20px;
            margin-bottom: 38px;
          }
        }
      }
    }
  }
}
</style>
