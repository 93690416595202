import { render, staticRenderFns } from "./singleform.vue?vue&type=template&id=7418e80e&scoped=true&"
import script from "./singleform.vue?vue&type=script&lang=js&"
export * from "./singleform.vue?vue&type=script&lang=js&"
import style0 from "./singleform.vue?vue&type=style&index=0&id=7418e80e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7418e80e",
  null
  
)

export default component.exports